import { graphql } from 'gatsby'
import React from 'react'
import Layout from './../components/help/Layout/Layout'
import Seo from '../components/help/Seo'
import Header from './../components/ui/Header/Header'
import HeaderFixed from './../components/ui/HeaderFixed/HeaderFixed'
import { getHrefToHome } from '../utils/getHrefToHome'
import Example from './../components/pages/News/Example/Example'
import PageNavigation from './../components/ui/PageNavigation/PageNavigation'
import { useDataHeaderByHy } from '../components/ui/Header/useDataHeaderByHy'
import { useDataHeaderByEn } from '../components/ui/Header/useDataHeaderByEn'

const NewsPageDetail = ({ data, location }) => {
    const isLangByHY = location.pathname.split('/')[1] !== 'en'
    const lang = isLangByHY ? 'hy' : 'en'

    const hrefHome = getHrefToHome(lang)
    const breadCrumbNews = lang === 'hy' ? `/news` : `/en/news`

    const breadCrumbs = [
        { name: data.seoMain.crumbsName, href: `${hrefHome}`, current: false },
        { name: data.seo.crumbsName, href: `${breadCrumbNews}`, current: true }
    ]

    const menyByHY = useDataHeaderByHy()
    const menyByEN = useDataHeaderByEn()
    const menuHeader = lang === 'hy' ? menyByHY.menu.nodes : menyByEN.menu.nodes

    return (
        <Layout lang={lang}>
            <Seo
                title={data.data.seo && data.data.seo.title ? data.data.seo.title : data.data.title}
                description={data.data.seo && data.data.seo.description ? data.data.seo.description : ''}
            />

            <Header lang={lang} menuHeader={menuHeader} />
            <HeaderFixed lang={lang} />
            <Example crumbs={breadCrumbs} data={data.data} lang={lang} />
            <PageNavigation links={data.seo.footerLinks} />
        </Layout>
    )
}

export const query = graphql`
    query pageQuery($pageId: String, $pageLang: String) {
        data: datoCmsRenshinNew(originalId: { eq: $pageId }, locale: { eq: $pageLang }) {
            date(formatString: "")
            urlCode
            title
            previewText
            detailText
            photoHorizontal {
                url
            }
            seo {
                title
                description
            }
        }

        seo: datoCmsRenshinSeo(pageName: { eq: "Press center" }, locale: { eq: $pageLang }) {
            crumbsName
            footerLinks {
                title
                url
            }
        }

        seoMain: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: $pageLang }) {
            crumbsName
        }
    }
`

export default NewsPageDetail
