export default function dateFormat(date, lang) {
    const dateFinal = {
        day: '',
        month: '',
        monthName: '',
        year: '',
        full: '',
    }

    if (date && lang) {
        const day = date.substr(8, 2)
        const month = date.substr(5, 2)
        const year = date.substr(0, 4)

        if (day && month && year && monthNames[lang]) {
            dateFinal.day = day
            dateFinal.month = month
            dateFinal.monthName = monthNames[lang][parseInt(month)]
            dateFinal.year = year
            dateFinal.full = `${day} ${monthNames[lang][parseInt(month)]} ${year}`
        }
    }

    return dateFinal
}

const monthNames = {
    en: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
    },
    hy: {
        1: 'հունվար',
        2: 'փետրվար',
        3: 'մարտ',
        4: 'ապրիլ',
        5: 'մայիս',
        6: 'հունիս',
        7: 'հուլիս',
        8: 'օգոստոս',
        9: 'սեպտեմբեր',
        10: 'հոկտեմբեր',
        11: 'նոյեմբեր',
        12: 'դեկտեմբեր',
    },
}