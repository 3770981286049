// extracted by mini-css-extract-plugin
export var Section = "Example-module--Section--TL7Ux";
export var LangHY = "Example-module--LangHY--geWK-";
export var Text = "Example-module--Text--Qy6W4";
export var ArticleHeader = "Example-module--ArticleHeader--LWsD8";
export var IconRightWrap = "Example-module--IconRightWrap--vhe2s";
export var IconRightWrapLink = "Example-module--IconRightWrapLink--BGgys";
export var ArrowBack = "Example-module--ArrowBack--4QQn3";
export var Title = "Example-module--Title--0TRjr";
export var Desc = "Example-module--Desc--IDGbP";
export var Image = "Example-module--Image--Jv-fc";
export var arrowLeft = "Example-module--arrowLeft--lAyZw";
export var arrowRight = "Example-module--arrowRight--X+AgZ";