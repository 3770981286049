import React from 'react'
import * as classes from './Example.module.scss'
import cn from 'classnames'
import Container from '../../../ui/Container/Container'
import BreadCrumbs from '../../../ui/BreadCrumbs/BreadCrumbs'
import { Link } from 'gatsby'
import IconRight from '../../../icons/IconRight'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import dateFormat from '../../../../utils/dateFormat'

function Example({ crumbs, data, lang }) {
    const previewText = deleteHtmlTag(data.previewText, 'p')
    const date = dateFormat(data.date, lang)

    return (
        <section className={cn(classes.Section, { [classes.LangHY]: lang === 'hy' })}>
            <Container>
                <BreadCrumbs list={crumbs} black />

                <div className={classes.ArticleHeader}>
                    <Link to={lang === 'hy' ? '/news/' : '/en/news/'} className={classes.IconRightWrapLink}>
                        <div className={classes.IconRightWrap}>
                            <IconRight className={classes.ArrowBack} />
                        </div>
                    </Link>

                    {date && date.full ? <SectionTitle title={date.full} lang={lang} /> : null}
                </div>

                {data.title && <h2 className={classes.Title} dangerouslySetInnerHTML={{ __html: data.title }} />}
                {previewText && <p className={classes.Desc} dangerouslySetInnerHTML={{ __html: previewText }} />}

                {data.photoHorizontal && data.photoHorizontal.url && (
                    <div className={classes.Image} style={{ backgroundImage: `url(${data.photoHorizontal.url})` }} />
                )}

                {data.detailText ? <div className={classes.Text} dangerouslySetInnerHTML={{ __html: data.detailText }} /> : null}
            </Container>
        </section>
    )
}

export default Example
